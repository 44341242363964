import AppWrapper from "@components/application/AppWrapper"
import { NextPage } from "next"
import type { AppProps } from "next/app"
import App from "next/app"
import Head from "next/head"
import { ReactElement, ReactNode, useEffect } from "react"
// import TagManager from "react-gtm-module"
import { useRouter } from "next/router"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { Shield } from "../src/common/routes/Shield"
import store from "../store"
import "../styles/globals.css"

let persistor = persistStore(store)

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function XApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  // useEffect(() => {
  //   TagManager.initialize({ gtmId: "GTM-5JDW8LM" })
  // }, [])

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("974622796859103")
        ReactPixel.pageView()

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  return (
    <>
      <Head>
        <title>XPAD - The Borderless Wallet</title>
        <meta
          name="description"
          content="The secure and simple way to send and receive money across borders. Make secure and affordable cross-border transactions on XPAD."
        />
        <meta
          name="facebook-domain-verification"
          content="mdp24e8mq4xxbsy9tzuhdd14ssmw8k"
        />
      </Head>
      {getLayout(
        <Provider store={store}>
          <AppWrapper>
            <ToastContainer />
            <PersistGate loading={null} persistor={persistor}>
              <Shield>
                <Component {...pageProps} />
              </Shield>
            </PersistGate>
          </AppWrapper>
        </Provider>
      )}
    </>
  )
}

XApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps }
}

export default XApp
